<template>
  <div class="search-result">
    <p class="search-result__title">{{ searchResult.Fields.Title.Value }}</p>
    <div class="search-result__meta">
      <p>{{ searchResult.Fields.StudyLevel.Value }}</p>
      <p v-if="searchResult.Fields.UCASCode">{{ searchResult.Fields.UCASCode.Value }}</p>
      <p v-if="searchResult.Fields.StudyType">{{ searchResult.Fields.StudyType.Value }}</p>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'SearchResult',
    props: {
      searchResult: Object
    },
  }
</script>
